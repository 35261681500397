<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="26"
    height="32"
    viewBox="0 0 26 32"
  >
    <defs>
      <path
        id="js7ea"
        d="M1003.995 430.368a.63.63 0 0 1 .253.053l9.706 4.209.66-.656-10.619-25.733-10.619 25.733.66.656 9.707-4.21a.63.63 0 0 1 .252-.052zm10.11 5.632a.635.635 0 0 1-.252-.053l-9.853-4.27-9.852 4.27a.639.639 0 0 1-.7-.13l-1.263-1.251a.619.619 0 0 1-.137-.68l11.368-27.533c.194-.47.973-.47 1.17 0l11.367 27.533c.096.234.042.5-.138.68l-1.263 1.251a.64.64 0 0 1-.447.183z"
      />
    </defs>
    <g>
      <g transform="translate(-991 -405)">
        <use xlink:href="#js7ea" />
        <use fill="#fff" fill-opacity="0" stroke="#000" stroke-miterlimit="50" stroke-width=".2" xlink:href="#js7ea" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheDirectionIcon',
};
</script>
