<template>
  <div class="map-marker" :style="{ zIndex: Number(id === activeId) }">
    <component :is="iconComponent" :inverse="isActive" />
  </div>
</template>

<script>
import MapMarkerArtFairIcon from '@/components/icons/map/MapMarkerArtFairIcon';
import MapMarkerArtSpaceIcon from '@/components/icons/map/MapMarkerArtSpaceIcon';
import MapMarkerEventIcon from '@/components/icons/map/MapMarkerEventIcon';
import MapMarkerExhibitionIcon from '@/components/icons/map/MapMarkerExhibitionIcon';
import MapMarkerHistoricalSiteIcon from '@/components/icons/map/MapMarkerHistoricalSiteIcon';

export default {
  name: 'MapMarkerEntityIcon',
  components: {
    MapMarkerArtFairIcon,
    MapMarkerArtSpaceIcon,
    MapMarkerEventIcon,
    MapMarkerExhibitionIcon,
    MapMarkerHistoricalSiteIcon,
  },
  props: {
    activeId: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconComponent() {
      const type = this.type.replace('Type', '');
      return `MapMarker${type}Icon`;
    },
    isActive() {
      return this.id === this.activeId;
    },
  },
};
</script>

<style lang="scss" scoped>
.map-marker {
  cursor: pointer;
  width: 50px;
  height: 50px;

  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
