<template>
  <div class="entity" @click="$emit('click', entity)">
    <div class="entity__image-container">
      <img v-if="mediaUrl" class="entity__image" :src="mediaUrl" alt="Image" />
      <div v-else class="entity__without-image" />
    </div>

    <div class="entity__content entity-content">
      <p class="entity-content__title">{{ entity.title }}</p>
      <p class="entity-content__type">{{ entity.type }}</p>
    </div>
  </div>
</template>

<script>
import ImageHandler from '@/helpers/ImageHandler';

export default {
  name: 'MapPopupEntityContent',
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mediaUrl() {
      return new ImageHandler().getFirstImgFromMedia(this.entity);
    },
  },
};
</script>

<style lang="scss" scoped>
.entity {
  display: flex;
  padding-right: 15px;

  &__image-container {
    position: relative;
  }

  &__image,
  &__without-image {
    width: 180px;
    height: 130px;
  }
  &__image {
    object-fit: cover;
    object-position: 50% 50%;
  }
  &__without-image {
    background-color: #d9d9d9;
  }

  &__staff-pick {
    position: absolute;
    top: 10px;
    right: -7px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 1px;
  }

  &-content {
    margin-left: 15px;

    &__title {
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    &__type {
      font-size: 14px;
      font-weight: 300;
      text-transform: capitalize;
      color: #acacac;
    }
  }
}
</style>
