<template>
  <svg width="48" height="48" viewBox="0 0 48 48" :fill="bgColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="21" :stroke="mainColor" stroke-width="2" />
    <path
      d="M16.7225 32.3185H16.107V33.6743H31.8929V32.3185H31.3318M16.7225 32.3185V31.232H17.5723M16.7225 32.3185H31.3318M31.3318 32.3185V31.232H30.4592M17.5723 31.232V30.3041H17.9514M17.5723 31.232H19.5531M19.5531 31.232V30.3041H19.2596M19.5531 31.232H21.3259M21.3259 31.232V30.3041H21.7049M21.3259 31.232H23.2577M23.2577 31.232V30.3041H22.9931M23.2577 31.232H26.8401M24.8839 31.232V30.3041H25.2874M26.8401 31.232V30.3041H26.51M26.8401 31.232H28.5763M28.5763 31.232V30.3041H28.8942M28.5763 31.232H30.4592M30.4592 31.232V30.3041H30.178M17.9514 30.3041V23.2878M17.9514 30.3041H19.2596M19.2596 30.3041V23.2878M21.7049 30.3041H22.9931M21.7049 30.3041V26.796V23.2878M22.9931 30.3041V23.2878M25.2874 30.3041C25.2874 27.5641 25.2874 26.0279 25.2874 23.2878M25.2874 30.3041H26.51M26.51 30.3041V23.2878M28.8942 30.3041H30.178M28.8942 30.3041V26.796V23.2878M30.178 30.3041V23.2878M17.5026 22.3691V23.2878H19.6577V22.3691M21.3262 22.3691V23.2878H23.3422V22.3691M24.8282 22.3691V23.2878H26.9572V22.3691M28.3302 22.3691V23.2878H30.659V22.3691M23.9728 16.7276L31.6904 22.1557H28.6034M23.9728 16.7276L16.2281 22.1557H19.3964M23.9728 16.7276V16.4199M19.3964 22.1557L23.9728 18.706L28.6034 22.1557M19.3964 22.1557H28.6034M23.9728 16.4199V14.3242L26.2252 15.584L23.9728 16.4199Z"
      :stroke="mainColor"
      stroke-width="0.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="22.6506" y="20.6719" width="0.507812" height="0.507812" :fill="mainColor" />
    <rect x="23.7977" y="20.6719" width="0.507812" height="0.507812" :fill="mainColor" />
    <rect x="25.0209" y="20.6719" width="0.507812" height="0.507812" :fill="mainColor" />
    <rect x="23.7461" y="19.5391" width="0.507812" height="0.507812" :fill="mainColor" />
  </svg>
</template>

<script>
export default {
  name: 'MapMarkerArtSpaceIcon',
  props: {
    inverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bgColor() {
      return this.inverse ? '#363636' : '#ffffff';
    },
    mainColor() {
      return this.inverse ? '#ffffff' : '#363636';
    },
  },
};
</script>
