<template>
  <Transition name="fade">
    <div v-if="value" class="no-route" @click.self="handleClose">
      <div class="wrapper">
        <button class="close-btn" type="button" @click="handleClose">
          <TheCrossIcon />
        </button>
        <span>{{ value }}</span>
      </div>
    </div>
  </Transition>
</template>

<script>
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';

export default {
  name: 'MapDrawDirectionErrorModal',
  components: { TheCrossIcon },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClose() {
      this.$emit('input', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.no-route {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  height: 100vh;
  background-color: rgba(#000, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    background-color: #fff;
    padding: 30px 20px 20px 20px;
    position: relative;
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
