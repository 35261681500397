<template>
  <svg width="48" height="48" viewBox="0 0 48 48" :fill="bgColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="21" :stroke="mainColor" stroke-width="2" />
    <path
      d="M19.4595 20.8935V15.7674H22.4966M19.4595 20.8935L21.9836 18.3694L22.738 19.1239M19.4595 20.8935H24.5076M22.4966 15.7674L24.0609 14.2031L25.6252 15.7674M22.4966 15.7674H25.6252M25.6252 15.7674H28.8044V20.8935H27.2781M24.5076 20.8935H27.2781M24.5076 20.8935L22.738 19.1239M27.2781 20.8935L24.1233 17.7387L22.738 19.1239"
      :stroke="mainColor"
      stroke-width="0.8"
      stroke-linecap="round"
    />
    <circle cx="26.6831" cy="17.4495" r="0.812775" :fill="mainColor" />
    <path d="M19.3869 33.793V25.7148" :stroke="mainColor" stroke-linecap="round" />
    <path
      d="M15.8866 29.7969C17.0475 29.176 19.3693 27.4723 19.3693 25.625"
      :stroke="mainColor"
      stroke-linecap="round"
    />
    <path
      d="M23.8679 29.865C21.8378 29.7873 19.3869 27.4723 19.3869 25.625"
      :stroke="mainColor"
      stroke-linecap="round"
    />
    <circle cx="19.3557" cy="25.158" r="1.30646" :fill="mainColor" />
    <path d="M28.613 33.793V25.7148" :stroke="mainColor" stroke-linecap="round" />
    <path
      d="M32.1133 29.7969C30.9525 29.176 28.6307 27.4723 28.6307 25.625"
      :stroke="mainColor"
      stroke-linecap="round"
    />
    <path d="M24.132 29.865C26.1621 29.7873 28.613 27.4723 28.613 25.625" :stroke="mainColor" stroke-linecap="round" />
    <circle r="1.30646" transform="matrix(-1 0 0 1 28.6442 25.158)" :fill="mainColor" />
  </svg>
</template>

<script>
export default {
  name: 'MapMarkerExhibitionIcon',
  props: {
    inverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bgColor() {
      return this.inverse ? '#363636' : '#ffffff';
    },
    mainColor() {
      return this.inverse ? '#ffffff' : '#363636';
    },
  },
};
</script>
