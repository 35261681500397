import { Dictionary } from 'vue-router/types/router';

export function prepareVariablesForSingleEntityQuery({ id, slug }: Dictionary<string>) {
  const variables: { id?: number | string; onlyId?: boolean; slug: string } = { slug };

  if (!isNaN(Number(id))) {
    variables.id = id;
  } else {
    variables.onlyId = true;
  }

  return variables;
}

export function redirectToSingleEntityRoute(
  name: string,
  id: string,
  slug: string,
  options: { params?: Dictionary<string>; query?: Dictionary<string | (string | null)[]> } = {}
) {
  options.params = options.params || {};
  options.query = options.query || {};

  return { name, params: { ...options.params, id, slug }, query: { ...options.query } };
}
