<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="31"
    height="31"
    viewBox="0 0 31 31"
  >
    <defs>
      <path
        id="pmm3a"
        d="M1015.625 496h-13.75a3.13 3.13 0 0 0-3.125 3.125v6.25a.625.625 0 0 0 1.25 0v-6.25c0-1.034.841-1.875 1.875-1.875h13.75c1.034 0 1.875.841 1.875 1.875v22.5a1.877 1.877 0 0 1-1.875 1.875h-13.75a1.877 1.877 0 0 1-1.875-1.875v-6.25a.625.625 0 0 0-1.25 0v6.25a3.13 3.13 0 0 0 3.125 3.125h13.75a3.13 3.13 0 0 0 3.125-3.125v-22.5a3.13 3.13 0 0 0-3.125-3.125zm-14.192 18.934a.625.625 0 0 0 .883.884l5-5a.624.624 0 0 0 .135-.204c.018-.044.02-.089.028-.135.006-.035.021-.068.021-.104 0-.048-.018-.09-.028-.134-.007-.034-.007-.07-.02-.102a.656.656 0 0 0-.14-.209l-4.996-4.996a.625.625 0 0 0-.884.883l3.934 3.933h-14.741a.625.625 0 0 0 0 1.25h14.741z"
      />
    </defs>
    <g>
      <g transform="translate(-989 -495)">
        <use xlink:href="#pmm3a" />
        <use fill="#fff" fill-opacity="0" stroke="#000" stroke-miterlimit="50" stroke-width=".2" xlink:href="#pmm3a" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheAccessIcon',
};
</script>
