<template>
  <svg width="48" height="48" viewBox="0 0 48 48" :fill="bgColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="21" :stroke="mainColor" stroke-width="2" />
    <path
      d="M16.8686 25.1836C16.8705 26.9865 16.4963 31.2433 14.9843 33.8477"
      :stroke="mainColor"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.4879 25.1836C31.4859 26.9865 31.8601 31.2433 33.3721 33.8477H14.9411"
      :stroke="mainColor"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.1235 17.7212C25.8665 20.2743 30.6232 22.2442 33.0066 22.9392H28.2194M24.1235 17.7212C23.4484 18.6404 20.1865 21.1202 14.9934 22.9392H19.443M24.1235 17.7212C22.626 20.5682 20.2306 22.3666 19.443 22.9392M24.1235 17.7212C25.1423 20.3841 27.1301 22.2899 28.2194 22.9392M19.443 22.9392H24.4756M28.2194 22.9392H24.4756M24.4756 22.9392V16.1283M24.4756 14.15V14.1484M24.4756 14.1484L26.992 15.2537L24.4756 16.1283M24.4756 14.1484V16.1283"
      :stroke="mainColor"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.1111 23.1793C20.1111 23.5055 20.0503 23.8285 19.932 24.1299C19.8138 24.4313 19.6404 24.7051 19.4219 24.9358C19.2034 25.1664 18.944 25.3494 18.6585 25.4742C18.373 25.5991 18.067 25.6633 17.758 25.6633C17.449 25.6633 17.143 25.5991 16.8575 25.4742C16.572 25.3494 16.3126 25.1664 16.0941 24.9358C15.8756 24.7051 15.7022 24.4313 15.584 24.1299C15.4657 23.8285 15.4048 23.5055 15.4048 23.1793L16.2121 23.1793C16.2121 23.3936 16.2521 23.6058 16.3297 23.8038C16.4074 24.0018 16.5213 24.1817 16.6649 24.3332C16.8084 24.4848 16.9788 24.605 17.1664 24.687C17.354 24.769 17.555 24.8112 17.758 24.8112C17.961 24.8112 18.162 24.769 18.3496 24.687C18.5372 24.605 18.7076 24.4848 18.8511 24.3332C18.9947 24.1817 19.1086 24.0018 19.1862 23.8038C19.2639 23.6058 19.3039 23.3936 19.3039 23.1793H20.1111Z"
      :fill="mainColor"
    />
    <path
      d="M24.3769 23.1793C24.3769 23.5055 24.316 23.8285 24.1978 24.1299C24.0795 24.4313 23.9062 24.7051 23.6877 24.9358C23.4692 25.1664 23.2098 25.3494 22.9243 25.4742C22.6388 25.5991 22.3328 25.6633 22.0237 25.6633C21.7147 25.6633 21.4087 25.5991 21.1232 25.4742C20.8377 25.3494 20.5783 25.1664 20.3598 24.9358C20.1413 24.7051 19.968 24.4313 19.8497 24.1299C19.7315 23.8285 19.6706 23.5055 19.6706 23.1793H20.4778C20.4778 23.3936 20.5178 23.6058 20.5955 23.8038C20.6732 24.0018 20.7871 24.1817 20.9306 24.3332C21.0742 24.4848 21.2446 24.605 21.4321 24.687C21.6197 24.769 21.8207 24.8112 22.0237 24.8112C22.2268 24.8112 22.4278 24.769 22.6153 24.687C22.8029 24.605 22.9733 24.4848 23.1169 24.3332C23.2604 24.1817 23.3743 24.0018 23.452 23.8038C23.5297 23.6058 23.5697 23.3936 23.5697 23.1793H24.3769Z"
      :fill="mainColor"
    />
    <path
      d="M28.6426 23.1793C28.6426 23.5055 28.5818 23.8285 28.4635 24.1299C28.3453 24.4313 28.1719 24.7051 27.9534 24.9358C27.7349 25.1664 27.4755 25.3494 27.19 25.4742C26.9045 25.5991 26.5985 25.6633 26.2895 25.6633C25.9805 25.6633 25.6745 25.5991 25.389 25.4742C25.1035 25.3494 24.8441 25.1664 24.6256 24.9358C24.4071 24.7051 24.2337 24.4313 24.1155 24.1299C23.9972 23.8285 23.9363 23.5055 23.9363 23.1793L24.7436 23.1793C24.7436 23.3936 24.7835 23.6058 24.8612 23.8038C24.9389 24.0018 25.0528 24.1817 25.1964 24.3332C25.3399 24.4848 25.5103 24.605 25.6979 24.687C25.8854 24.769 26.0865 24.8112 26.2895 24.8112C26.4925 24.8112 26.6935 24.769 26.8811 24.687C27.0687 24.605 27.2391 24.4848 27.3826 24.3332C27.5262 24.1817 27.6401 24.0018 27.7177 23.8038C27.7954 23.6058 27.8354 23.3936 27.8354 23.1793H28.6426Z"
      :fill="mainColor"
    />
    <path
      d="M32.9084 23.1793C32.9084 23.5055 32.8475 23.8285 32.7293 24.1299C32.611 24.4313 32.4377 24.7051 32.2192 24.9358C32.0007 25.1664 31.7412 25.3494 31.4557 25.4742C31.1703 25.5991 30.8643 25.6633 30.5552 25.6633C30.2462 25.6633 29.9402 25.5991 29.6547 25.4742C29.3692 25.3494 29.1098 25.1664 28.8913 24.9358C28.6728 24.7051 28.4995 24.4313 28.3812 24.1299C28.263 23.8285 28.2021 23.5055 28.2021 23.1793H29.0093C29.0093 23.3936 29.0493 23.6058 29.127 23.8038C29.2047 24.0018 29.3185 24.1817 29.4621 24.3332C29.6057 24.4848 29.7761 24.605 29.9636 24.687C30.1512 24.769 30.3522 24.8112 30.5552 24.8112C30.7583 24.8112 30.9593 24.769 31.1468 24.687C31.3344 24.605 31.5048 24.4848 31.6484 24.3332C31.7919 24.1817 31.9058 24.0018 31.9835 23.8038C32.0612 23.6058 32.1012 23.3936 32.1012 23.1793H32.9084Z"
      :fill="mainColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'MapMarkerArtFairIcon',
  props: {
    inverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bgColor() {
      return this.inverse ? '#363636' : '#ffffff';
    },
    mainColor() {
      return this.inverse ? '#ffffff' : '#363636';
    },
  },
};
</script>
