<template>
  <div class="page-wrapper">
    <div v-if="!mapReadyToLoading" class="app-loader-wrapper">
      <AppLoader />
    </div>
    <div v-else class="h-100">
      <div class="page-wrap destination-h24">
        <div class="destination-h24__main-content">
          <div class="destination-h24__map">
            <MglMap :access-token="mapAccessToken" :map-style="mapStyle" @load="onMapLoaded">
              <MglGeojsonLayer
                :layerId="mapCurrentPositionLayer.id"
                :layer="mapCurrentPositionLayer"
                :sourceId="mapCurrentPositionSource.id"
                :source="mapCurrentPositionSource.source"
              />
              <MglGeojsonLayer
                :layerId="mapRouteDestinationLayer.id"
                :layer="mapRouteDestinationLayer"
                :sourceId="mapRouteDestinationSource.id"
                :source="mapRouteDestinationSource.source"
              />
              <MglMarker
                v-for="(marker, markerIndex) in mapMarkers"
                :key="`marker_${marker.key}`"
                ref="markerRefs"
                :coordinates="[marker.lng, marker.lat]"
                :offset="[0, -14]"
              >
                <MapMarkerEntityIcon
                  slot="marker"
                  :active-id="markerActiveKey"
                  :id="marker.key"
                  :type="marker.iconType"
                />
                <MglPopup
                  @open="onMarkerPopupOpened($event, marker, markerIndex)"
                  @close="onMarkerPopupClosed($event, marker)"
                >
                  <MapPopupEntityContent :entity="marker" @click="handleOpenEntityDetailsModal" />
                </MglPopup>
              </MglMarker>
            </MglMap>
          </div>

          <div class="destination-h24__sidebar" :class="{ 'active-entity-details-modal': showEntityDetailsModal }">
            <div class="destination-h24__head">
              <div class="d-flex flex-column w-100">
                <div class="d-flex justify-content-between w-100">
                  <div class="d-flex flex-column">
                    <div class="page-sidebar-header">Destination map</div>
                    <span v-if="destinationTitle" class="destination-title">{{ destinationTitle }}</span>
                  </div>
                  <div class="d-flex close-page">
                    <RouterLink :to="destinationRouteTo">
                      <TheCrossIcon />
                    </RouterLink>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <AppDataFilterOpenButton :appearing-with-animation="false" @click="handleOpenFilter" />
                </div>
              </div>
            </div>

            <ul class="destination-h24__items">
              <li
                v-for="(entity, entityIndex) in destinationEntities"
                :key="`entity_${entity.__typename}_${entity.id}`"
                class="destination-h24__item"
                @click="selectMapMarker(entityIndex)"
              >
                <div
                  v-if="entity.media && entity.media[0] && entity.media[0].url"
                  class="destination-h24__image"
                  :style="`background-image:url(${entity.media[0].url})`"
                />
                <div v-else class="destination-h24__image" />

                <div class="destination-h24__item-content">
                  <h5 class="destination-h24__caption">{{ entity.title }}</h5>
                  <div v-if="entity.__typename === 'ArtSpaceType'" class="small-grey destination-h24__item-type">
                    {{ entity.type }}
                  </div>
                  <div v-else class="small-grey destination-h24__item-type">
                    {{ entity.category || entity.entityType }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <BackHeader :description="destinationTitle" :is-static="false" title="Destination map" with-filter />

    <AppDataFilterMain
      v-model="filterValues"
      :filter-groups="filterGroups"
      :init-open="isAppDataFilterOpened"
      :is-mobile="isMobileScreen"
      @input="handleChangeFilterValues"
      @open="handleOpenFilter"
      @close="handleCloseFilter"
    />

    <Transition :name="entityDetailsModalTransition">
      <DestinationMapEntityDetails
        v-if="showEntityDetailsModal"
        :entity="entityDetails"
        @close="handleCloseEntityDetailsModal"
        @get-map-direction="drawMapDirection(entityDetails)"
        @show-access-instructions="openAccessInfoModal"
      />
    </Transition>

    <AccessInfoModal
      v-if="areAccessInstructionsPresent"
      v-model="isAccessInfoModal"
      :items="entityDetails.accessInstructions"
      @close="closeAccessInfoModal"
    />
    <MapDrawDirectionErrorModal v-model="drawMapDirectionErrorMsg" />
  </div>
</template>

<script>
import { MglGeojsonLayer, MglMap, MglMarker, MglPopup } from 'vue-mapbox';
import {
  getMapBounds,
  getMapCurrentPositionSource,
  getMapDirectionFromCurrentPosition,
  getMapRouteDestinationSource,
  MAP_ACCESS_TOKEN,
  MAP_LAYER_CURRENT_POSITION,
  MAP_LAYER_ROUTE_DESTINATION,
  MAP_STYLE_URL,
} from '@/helpers/mapboxHelper';
import { getEntityType } from '@/helpers/entityType';
import { areRouteQueriesDifferent } from '@/helpers/routerHelper';
import DestinationMapPageFilterHelper from '@/helpers/data-filter/DestinationMapPageFilterHelper';
import { navigatorCurrentPosition, validateAndGetCoordinates } from '@/helpers/GeolocationHelper';
import { prepareVariablesForSingleEntityQuery } from '@/helpers/graphqlHelper';

import destinationWithEntitiesQuery from '@/graphql/destination/DestinationWithEntities.query.gql';

import BackHeader from '@/components/partials/BackHeader';
import AccessInfoModal from '@/components/partials/AccessInfoModal';
import AppDataFilterMain from '@/components/data-filter/AppDataFilterMain';
import AppDataFilterOpenButton from '@/components/data-filter/AppDataFilterOpenButton';
import DestinationMapEntityDetails from '@/components/partials/DestinationMapEntityDetails';
import MapPopupEntityContent from '@/components/partials/MapPopupEntityContent';
import MapMarkerEntityIcon from '@/components/partials/MapMarkerEntityIcon';
import MapDrawDirectionErrorModal from '@/components/partials/MapDrawDirectionErrorModal';
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';

export default {
  name: 'DestinationMapPage',
  components: {
    TheCrossIcon,
    MapDrawDirectionErrorModal,
    AccessInfoModal,
    AppDataFilterOpenButton,
    AppDataFilterMain,
    BackHeader,
    DestinationMapEntityDetails,
    MapMarkerEntityIcon,
    MapPopupEntityContent,
    MglMap,
    MglMarker,
    MglPopup,
    MglGeojsonLayer,
  },
  metaInfo() {
    const title = this.destinationTitle ? `${this.destinationTitle}: map` : 'Destination: map';

    return {
      title,
    };
  },

  data() {
    return {
      destinationTitle: '',
      destinationEntities: [],

      mapAccessToken: MAP_ACCESS_TOKEN,
      mapStyle: MAP_STYLE_URL,
      mapReadyToLoading: false,
      mapMarkers: [],
      markerActiveKey: null,
      markerEntityActiveIndex: null,

      mapCurrentPositionLayer: MAP_LAYER_CURRENT_POSITION,
      mapRouteDestinationLayer: MAP_LAYER_ROUTE_DESTINATION,
      mapCurrentPositionSourceCoords: [],
      mapRouteDestinationSourceCoords: [],
      drawMapDirectionErrorMsg: '',

      entityDetails: {},
      showEntityDetailsModal: false,
      isAccessInfoModal: false,

      filterGroups: [],
      filterValues: {},
      changingRouteAfterFilterUpdated: false,
    };
  },

  computed: {
    areAccessInstructionsPresent() {
      return !!this.entityDetails?.accessInstructions?.length;
    },
    destinationRouteTo() {
      const { id, slug } = this.$route.params;

      return { name: 'destination', params: { id, slug } };
    },
    entityDetailsModalTransition() {
      return this.isMobileScreen ? 'slide-up-full' : 'slide';
    },
    filterQueryVariables() {
      return DestinationMapPageFilterHelper.prepareFilterValuesToQueryVariables(this.filterValues);
    },
    isAppDataFilterOpened() {
      return !!this.$store.state.isAppDataFilterOpened;
    },
    isMobileScreen() {
      return !!this.$store.state.isMobileScreen;
    },
    mapCurrentPositionSource() {
      return getMapCurrentPositionSource(this.mapCurrentPositionSourceCoords);
    },
    mapRouteDestinationSource() {
      return getMapRouteDestinationSource(this.mapRouteDestinationSourceCoords);
    },
  },

  async created() {
    navigatorCurrentPosition(
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', false);
      },
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', true);
      }
    );

    this.filterGroups = DestinationMapPageFilterHelper.prepareFilterGroups();
    this.filterValues = DestinationMapPageFilterHelper.getFilterValuesFromRouteQuery(this.$route.query);

    await this.fetchDestination();
    this.setMapMarkers();
    this.mapReadyToLoading = true;
  },
  async beforeRouteUpdate(to, from, next) {
    if (!this.changingRouteAfterFilterUpdated) {
      this.filterValues = DestinationMapPageFilterHelper.getFilterValuesFromRouteQuery(to.query);
    }
    await this.fetchDestination(true);
    this.setMapMarkers();
    this.changingRouteAfterFilterUpdated = false;
    next();
  },

  methods: {
    async fetchDestination(onlyEntities = false) {
      const variables = prepareVariablesForSingleEntityQuery(this.$route.params);

      const result = await this.$apollo.query({
        query: destinationWithEntitiesQuery,
        fetchPolicy: 'no-cache',
        variables: {
          ...this.filterQueryVariables,
          slug: variables.slug,
          onlyEntities,
        },
      });

      const destination = result.data?.destinationWithEntities;
      if (!destination) {
        return;
      }

      this.destinationEntities = destination.entities.map((e) => ({ ...e, entityType: getEntityType(e) }));
      if (!onlyEntities) {
        this.destinationTitle = destination.title;
      }
    },

    handleOpenFilter() {
      this.$store.dispatch('toggleAppDataFilter', true);
    },
    handleCloseFilter() {
      this.$store.dispatch('toggleAppDataFilter', false);
    },
    handleChangeFilterValues() {
      this.handleCloseEntityDetailsModal();
      this.clearMapDirection();

      const query = DestinationMapPageFilterHelper.setFilterValuesToRouteQuery(this.filterValues);

      if (areRouteQueriesDifferent(this.$route.query, query)) {
        this.changingRouteAfterFilterUpdated = true;
        this.$router.push({ name: this.$route.name, params: this.$route.params, query });
      }
    },

    handleOpenEntityDetailsModal(entity) {
      this.showEntityDetailsModal = true;
      this.entityDetails = entity;
    },
    handleCloseEntityDetailsModal() {
      this.showEntityDetailsModal = false;
      if (this.isMobileScreen) {
        const currentActiveMarker = this.$refs.markerRefs[this.markerEntityActiveIndex];
        if (currentActiveMarker) {
          currentActiveMarker.togglePopup();
        }
      }
    },

    openAccessInfoModal() {
      if (this.areAccessInstructionsPresent) {
        this.isAccessInfoModal = true;
      }
    },
    closeAccessInfoModal() {
      this.isAccessInfoModal = false;
    },

    onMapLoaded({ map }) {
      map.fitBounds(getMapBounds(this.mapMarkers), { padding: 80 });
    },
    setMapMarkers() {
      const mapMarkers = [];

      this.destinationEntities.forEach((e) => {
        const coordinates = validateAndGetCoordinates(e);
        if (coordinates === false) {
          return;
        }
        mapMarkers.push({
          id: e.id,
          key: `${e.__typename}_${e.id}`,
          __typename: e.__typename,
          slug: e.slug,
          title: e.title,
          lat: coordinates.latitude,
          lng: coordinates.longitude,
          iconType: e.__typename,
          media: e.media,
          address: e.address,
          phone: e.phone,
          type: e.type || e.entityType,
          category: e.category,
          staff_pick: e.staff_pick || false,
        });
      });

      this.mapMarkers = [];
      this.$nextTick().then(() => {
        this.mapMarkers = mapMarkers;
      });
    },
    selectMapMarker(entityIndex) {
      const selectedMarker = this.$refs.markerRefs[entityIndex];
      if (!selectedMarker) {
        return;
      }
      const currentActiveMarker = this.$refs.markerRefs[this.markerEntityActiveIndex];
      if (currentActiveMarker) {
        currentActiveMarker.togglePopup();
      }
      selectedMarker.togglePopup();
      this.markerEntityActiveIndex = entityIndex;
    },
    onMarkerPopupOpened(data, marker, markerIndex) {
      this.markerActiveKey = marker.key;
      this.markerEntityActiveIndex = markerIndex;
      if (this.isMobileScreen) {
        this.handleOpenEntityDetailsModal(marker);
      }
    },
    onMarkerPopupClosed(data, marker) {
      if (this.markerActiveKey === marker.key) {
        this.markerActiveKey = null;
        this.markerEntityActiveIndex = null;
        this.showEntityDetailsModal = false;
      }
      if (!this.isMobileScreen) {
        this.showEntityDetailsModal = false;
      }
    },
    drawMapDirection(destinationCoordinates) {
      getMapDirectionFromCurrentPosition(destinationCoordinates)
        .then(({ currentPosition, routeCoordinates }) => {
          this.mapRouteDestinationSourceCoords = routeCoordinates;
          if (
            !this.mapCurrentPositionSourceCoords.length ||
            (currentPosition[0] !== this.mapCurrentPositionSourceCoords[0] &&
              currentPosition[1] !== this.mapCurrentPositionSourceCoords[1])
          ) {
            this.mapCurrentPositionSourceCoords = currentPosition;
          }
        })
        .catch((err) => {
          this.clearMapDirection();
          this.drawMapDirectionErrorMsg = err.message;
        });
    },
    clearMapDirection() {
      this.mapCurrentPositionSourceCoords = [];
      this.mapRouteDestinationSourceCoords = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.page-sidebar-header {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3.43px;
  text-transform: uppercase;
  color: #353535;

  @media (min-width: 768px) {
    font-size: 22px;
  }
  @media (min-width: 992px) {
    font-size: 24px;
  }
}

.destination-title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.14px;
  text-transform: uppercase;
  color: #363636;
  opacity: 0.5;
}

.close-page {
  padding-top: 2px;

  @media (min-width: 992px) {
    padding-top: 5px;
  }
}

.active-entity-details-modal {
  overflow-y: hidden;
  opacity: 0.5;

  @media (min-width: 768px) {
    opacity: 1;
  }
}
</style>
