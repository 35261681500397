<template>
  <svg width="48" height="48" viewBox="0 0 48 48" :fill="bgColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="21" :stroke="mainColor" stroke-width="2" />
    <path
      d="M24.1186 14.9492L14.891 24.082L14.9352 24.1491C15.3578 24.7902 15.376 25.6167 14.9821 26.2758L14.9395 26.3471L21.6279 33.0495L21.854 32.9274C22.4523 32.6043 23.1739 32.6074 23.7695 32.9356L23.9763 33.0495L33.109 24.082L33.0472 23.9903C32.6328 23.376 32.5921 22.5829 32.9414 21.9293L33.109 21.6158L26.4907 14.9492L26.3346 15.043C25.7008 15.4238 24.9086 15.4238 24.2747 15.043L24.1186 14.9492Z"
      :stroke="mainColor"
      stroke-linecap="round"
    />
    <path
      d="M26.5773 20.5664L24.5498 22.5938L22.0545 21.1694L23.4191 23.8011L21.2552 25.7116L24.004 25.3802L25.505 27.7781L25.817 25.0488L28.4877 24.5419L26.1484 23.4697L26.5773 20.5664Z"
      :stroke="mainColor"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.4136 19.8438L28.1809 20.5657"
      :stroke="mainColor"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.7841 16.5703L25.5514 17.2922"
      :stroke="mainColor"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.5016 22.3516L31.269 23.0735"
      :stroke="mainColor"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.2327 29.707L24 30.429"
      :stroke="mainColor"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.9668 27.2539L20.7341 27.9758"
      :stroke="mainColor"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.4776 23.9883L18.2449 24.7102"
      :stroke="mainColor"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'MapMarkerEventIcon',
  props: {
    inverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bgColor() {
      return this.inverse ? '#363636' : '#ffffff';
    },
    mainColor() {
      return this.inverse ? '#ffffff' : '#363636';
    },
  },
};
</script>
